import handleSubmition from '../submition';

export default function setupNavs(root, slider, app) {
  const next = root.querySelector('button.next');
  const prev = root.querySelectorAll('button.prev');

  if (next) {
    next.addEventListener('click', (event) => {
      event.preventDefault();

      if (app.step === 1 && app.communication < 4) {
        slider.slideNext();
      } else {
        handleSubmition(root, app, slider, next);
      }
    });
  }

  if (prev && prev.length) {
    [...prev].forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault();

        slider.slidePrev();
      });
    });
  }

  return next;
}
