export default function validatePhoneNumber(field, value, hint = true) {
  let valid = true;

  if (
    !value.trim().length ||
    (value.trim().length &&
      !/^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im.test(
        field.value
          .trim()
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replaceAll('(', '')
          .replaceAll(')', '')
      ))
  ) {
    if (hint) field.classList.add('error');

    valid = false;
  } else {
    field.classList.remove('error');
  }

  return valid;
}
