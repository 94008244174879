import { Swiper, Navigation, EffectCoverflow } from 'swiper';

export default function sliderCard(className) {
  // eslint-disable-next-line no-new
  const slider = new Swiper(className, {
    modules: [Navigation, EffectCoverflow],
    slidesPerView: 'auto',
    spaceBetween: 15,
    centeredSlides: true,
    loop: true,
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      scale: 0.85,
      modifier: 1,
      slideShadows: false
    },
    navigation: {
      nextEl: `${className}-btn-next`,
      prevEl: `${className}-btn-prev`
    }
  });

  return slider;
}
