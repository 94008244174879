import { Swiper, Navigation, Pagination } from 'swiper';

export default function sliderWide(className) {
  // eslint-disable-next-line no-new
  const slider = new Swiper(className, {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 15,
    centeredSlides: true,
    loop: true,
    speed: 1000,
    pagination: {
      el: `${className}-pagination`,
      clickable: true
    },
    navigation: {
      nextEl: `${className}-btn-next`,
      prevEl: `${className}-btn-prev`
    }
  });

  return slider;
}
