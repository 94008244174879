const ACTION = { domain: window.location.origin };

async function sendForm(endpoint, formData) {
  const response = await fetch(endpoint, {
    method: 'POST',
    body: formData
  });

  return response;
}

export default function handleSubmition(root, app, slider, button) {
  const form = root.querySelector('form');
  if (!form) return;

  const id = form.dataset.formId;

  button.classList.add('loading');

  const endpoint = `${ACTION.domain}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`;

  const formData = new FormData();
  formData.append('communication', app.communication);
  formData.append('phone', app.phone);

  sendForm(endpoint, formData).then((response) => {
    if (response.status === 200) {
      app.communication = 5;
      app.phone = '';

      button.classList.remove('loading');

      slider.slideTo(2);

      setTimeout(() => {
        window.location.reload();
      }, 10000);
    } else {
      button.classList.remove('loading');

      // eslint-disable-next-line no-alert
      alert('Виникла помилка! Будь-ласка, зверніться до адміністратора.');
    }
  });
}
