import validatePhoneNumber from './validation';

export default function setupField(root, node, next, app) {
  const field = root.querySelector(`${node}--field input[type=tel]`);
  field.addEventListener('input', () => {
    const valid = validatePhoneNumber(field, field.value);

    if (valid) {
      next.removeAttribute('disabled');

      app.phone = field.value;
    } else {
      next.setAttribute('disabled', '');
    }
  });

  return field;
}
