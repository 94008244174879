export default function setupRadiogroup(root, node, next, app) {
  const radiogroup = root.querySelector(`${node}--radio-group`);
  const radios = radiogroup.querySelectorAll('input[type=radio]');

  if (radios && radios.length) {
    [...radios].forEach((radio) => {
      radio.addEventListener('change', (event) => {
        next.removeAttribute('disabled');

        app.communication = event.target.value;
      });
    });
  } else {
    next.removeAttribute('disabled');
  }

  return radios;
}
