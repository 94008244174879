import Application from './application';
import setupSlider from './slider/slider';
import handleSlideChange from './slider/slide-change';
import setupNavs from './slider/navigation';
import setupRadiogroup from './fields/radiogroup';
import setupField from './fields/field';

export default function setupApplication(node) {
  const root = document.querySelector(node);
  if (!root) return;

  const app = new Application(node);

  const slider = setupSlider(node);
  const next = setupNavs(root, slider, app);

  const radios = setupRadiogroup(root, node, next, app);
  const field = setupField(root, node, next, app);

  handleSlideChange(root, slider, app, next, radios, field);
}
