import SimpleParallax from 'simple-parallax-js';

import sliderCard from './modules/slider-card';
import sliderWide from './modules/slider-wide';
import sliderBrands from './modules/slider-brands';
import ourMastersSlider from './modules/ourMastersSlider';
import parallax from './modules/parallax';
import serviceList from './modules/serviceList';
import anchorScroll from './modules/anchor-scroll';

import setupApplication from './modules/application';

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  sliderCard('.slider-card__swiper');
  sliderWide('.slider-wide__swiper');
  sliderBrands('.slider-brands__swiper-step');
  ourMastersSlider();
  parallax();
  serviceList.init();
  anchorScroll();

  const mobMenu = document.querySelector('.mobile__btn');
  const menu = document.querySelector('.header__menu');




  setTimeout(()=> {
    const footer = document.querySelector('.footer');
    const button = document.querySelector('.helper-widget-button');
    if (footer && button) {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };

      /* eslint-disable */
      const observer = new IntersectionObserver(observerCallback, observerOptions);

      function observerCallback(entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            button.classList.add('hidden');
          } else {
            button.classList.remove('hidden');
          }
        });
      }
      observer.observe(footer);
    }
  }, 2000)

  if (mobMenu && menu) {
    mobMenu.addEventListener('click', () => {
      menu.classList.toggle('open');
      mobMenu.classList.toggle('open');
      document.body.classList.toggle('hidden');
      document.documentElement.classList.toggle('hidden');
    });

    window.addEventListener('resize', () => {
      if (window.innerWidth > 1199) {
        mobMenu.classList.remove('open');
        document.body.classList.remove('hidden');
        document.documentElement.classList.remove('hidden');
        menu.classList.remove('open');
      }
    });
  }


  // parallax
  const parallaxImages = document.getElementsByClassName('site-parallax');

  // eslint-disable-next-line no-new
  new SimpleParallax(parallaxImages, {
    delay: 0.9,
    transition: 'cubic-bezier(0,0,0,1)'
  });

  setupApplication('.application');
});
