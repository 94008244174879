import { Swiper, Autoplay } from 'swiper';

export default function sliderBrands(className) {
  // eslint-disable-next-line no-new
  for (let i = 0; i < className.length; i += 1) {
    const oddNumber = i % 2;
    const arr = [];
    if (oddNumber === 0) {
      const slider = new Swiper(`${className}${i}`, {
        modules: [Autoplay],
        speed: 7000,
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        // spaceBetween: 50,
        preventInteractionOnTransition: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: false
        },
        breakpoints: {
          320: {
            spaceBetween: 20
          },
          1400: {
            spaceBetween: 50
          }
        }
      });
      arr.push(slider);
    } else {
      const slider = new Swiper(`${className}${i}`, {
        modules: [Autoplay],
        speed: 7000,
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        // spaceBetween: 50,
        preventInteractionOnTransition: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: true
        },
        breakpoints: {
          320: {
            spaceBetween: 20
          },
          1400: {
            spaceBetween: 50
          }
        }
      });
      arr.push(slider);
    }
  }
}
