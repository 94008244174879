import Swiper, { Navigation } from 'swiper';

export default function ourMastersSlider() {
  const masters = document.querySelectorAll('.our-masters__slider');

  if (!masters) return;

  masters.forEach((el) => {
    const slider = el.querySelector('.swiper');
    const prev = el.querySelector('.button-prev');
    const next = el.querySelector('.button-next');

    const swiper = new Swiper(slider, {
      modules: [Navigation],
      // slidesPerView: 'auto',
      loop: true,
      spaceBetween: 0,
      // slidesPerView: 4.1,
      // spaceBetween: 20,
      centeredSlides: true,
      allowTouchMove: true,
      preventInteractionOnTransition: true,
      navigation: {
        nextEl: next,
        prevEl: prev
      },
      breakpoints: {
        320: {
          slidesPerView: 1.5
        },
        480: {
          slidesPerView: 2.3
        },
        768: {
          slidesPerView: 2.7  
        },
        1400: {
          slidesPerView: 4.1
        }
      }
    });

    swiper.init();
  });
}
