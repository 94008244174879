export default {
  init() {
    const sectionList = document.querySelector('.services-list');
    const mobileSize = window.innerWidth <= 1025;

    if (!sectionList) return;

    /* eslint-disable */

    window.cursorAnimation = {
      initMouseDecorator() {
        const id = 'mouse_decorator';
        const el = document.getElementById(id);

        let diameter = 0;
        let xStart = 0;
        let yStart = 0;
        let offset = 1;
        let easing = 6;
        let inited = false;

        const alt = {
          x: 0,
          y: 0,
          d: diameter
        };
        const cur = {
          x: 0,
          y: 0,
          d: diameter
        };

        el.style = `height:${diameter}px;width:${diameter}px;`;

        function watchMove(e) {
          const dX = alt.x - cur.x;
          const dY = alt.y - cur.y;

          cur.x += dX / easing + offset;
          cur.y += dY / easing + offset;

          const t3d = `translate3d(${cur.x - cur.d / 2}px,${cur.y - cur.d / 2}px,0)`;

          const dD = alt.d - cur.d;
          cur.d += dD / easing;
          el.style.height = cur.d + 'px';
          el.style.width = cur.d + 'px';

          el.style.webkitTransform = t3d;
          el.style.transform = t3d;

          try {
            requestAnimationFrame(watchMove);
          } catch (_) {
            setImmediate(watchMove);
          }
        }

        const sli = document.querySelector('.services-list__items');
        sli.removeEventListener('mousemove', watchMove);
        sli.addEventListener('mousemove', (e) => {
          alt.x = e.clientX;
          alt.y = e.clientY;
          if (!inited) {
            cur.x = alt.x;
            cur.y = alt.y;
            inited = true;
            watchMove(e);
          }
        });

        const watchEl = [...document.getElementsByClassName('services-list__item')];
        watchEl.map((node) =>
          node.addEventListener('mouseover', () => {
            onElOver(node);
          })
        );

        watchEl.map((node) =>
          node.addEventListener('mouseover', (e) => {
            alt.x = e.clientX;
            alt.y = e.clientY;
            if (!inited) {
              cur.x = alt.x;
              cur.y = alt.y;
              inited = true;
              watchMove(e);
            }
          })
        );

        function onElOver(node, posX, posY) {
          const imageEl = node.querySelector(`.services-list__item-image-hover`);
          const imageElSrc = imageEl.querySelector('img').src;

          if (imageElSrc) {
            el.style.backgroundImage = `url(${imageElSrc})`;
            xStart = posX;
            yStart = posY;
            alt.d = diameter + 140;
            offset = 0;
          }
        }

        [...watchEl].map((node) =>
          node.addEventListener('mouseleave', (e) => {
            alt.d = diameter;
            offset = 5;
          })
        );
      }
    };

    if (mobileSize) return;

    cursorAnimation.initMouseDecorator();
  }
};
