import validatePhoneNumber from '../fields/validation';

export default function handleSlideChange(root, slider, app, next, radios, field) {
  slider.on('slideChange', (event) => {
    app.step = event.activeIndex + 1;

    const rootCurrent = [...root.classList].filter((className) => className.includes('step-'))[0];
    root.classList.remove(rootCurrent);
    root.classList.add(`step-${event.activeIndex + 1}`);

    if (app.step === 1) {
      if (radios && radios.length) {
        if ([...radios].some((radio) => radio.checked)) {
          next.removeAttribute('disabled');
        } else {
          next.setAttribute('disabled', '');
        }
      } else {
        next.removeAttribute('disabled');
      }
    } else if (app.step === 2) {
      const valid = validatePhoneNumber(field, field.value, false);

      if (valid) {
        next.removeAttribute('disabled');
      } else {
        next.setAttribute('disabled', '');
      }
    } else {
      next.setAttribute('disabled', '');
    }
  });
}
