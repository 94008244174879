import { Swiper, EffectFade } from 'swiper';

export default function setupSlider(node) {
  const slider = new Swiper(`${node}--slider`, {
    modules: [EffectFade],
    loop: false,
    slidesPerView: 1,
    effect: 'fade',
    speed: 1400,
    allowTouchMove: false
  });

  return slider;
}
