import simpleParallax from 'simple-parallax-js';

export default function Parallax() {
  const elems = document.querySelectorAll('.parallax');

  if (!elems) return;
  /* eslint-disable */
  new simpleParallax(elems, {
    orientation: 'down',
    delay: 0.6,
    overflow: true,
    transition: 'ease'
  });
}
