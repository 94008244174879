export default class Application {
  constructor(node) {
    this.node = node;
    this._step = 1;
    this._communication = 5;
    this._phone = '';
  }

  set step(value) {
    this._step = value;
  }

  get step() {
    return this._step;
  }

  set communication(value) {
    this._communication = value;
  }

  get communication() {
    return this._communication;
  }

  set phone(value) {
    this._phone = value;
  }

  get phone() {
    return this._phone;
  }
}
